@font-face {
    font-family: "AlsHaussBlack";
    src: url('fonts/alsHauss/als_hauss_black.woff2') format('woff2'), url('fonts/alsHauss/als_hauss_black.woff') format('woff');
    font-weight: 900;
}
@font-face {
    font-family: "AlsHaussBold";
    src: url('fonts/alsHauss/als_hauss_bold.woff2') format('woff2'), url('fonts/alsHauss/als_hauss_bold.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: "AlsHaussMedium";
    src: url('fonts/alsHauss/als_hauss_medium.woff2') format('woff2'), url('fonts/alsHauss/als_hauss_medium.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: "AlsHaussRegular";
    src: url('fonts/alsHauss/als_hauss_regular.woff2') format('woff2'), url('fonts/alsHauss/als_hauss_regular.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: "TradeGothicNextCondensedHeavy";
    src: url('fonts/tradeGothicNextLTPro/trade_gothic_next_condensed_heavy.woff2') format('woff2'), url('fonts/tradeGothicNextLTPro/trade_gothic_next_condensed_heavy.woff') format('woff');
    font-weight: 900;
}
@font-face {
    font-family: "TradeGothicNextCondensedBold";
    src: url('fonts/tradeGothicNextLTPro/trade_gothic_next_condensed_bold.woff2') format('woff2'), url('fonts/tradeGothicNextLTPro/trade_gothic_next_condensed_bold.woff') format('woff');
    font-weight: 700;
}
