// Main app styles

// Custom branding color-variables
$primary: #4b57e7;
$secondary: #b6e7df;
$light: #e4e8ff;
$dark: #13056b;
$grey: #6a737b;
$successLight:#21B530;
// import bootstrap variables and functions
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

[data-bs-theme='dark'] {
	--bs-black-rgb: 255, 255, 255 !important;
	--bs-white-rgb: 30, 28, 30 !important;
	--bs-dark-rgb: 248, 249, 250 !important;
	--bs-light-rgb: 55, 54, 55 !important;
}

// create custom theme colors for primary color
$custom-theme-colors: (
	'primary-50': lighten($primary, 50%),
	'primary-100': lighten($primary, 40%),
	'primary-200': lighten($primary, 32%),
	'primary-300': lighten($primary, 20%),
	'primary-400': lighten($primary, 10%),
	'primary-500': darken($primary, 10%),
	'primary-600': darken($primary, 20%),
	'primary-700': darken($primary, 30%),
	'primary-800': darken($primary, 40%),
	'primary-900': darken($primary, 50%),
	'secondary-50': lighten($secondary, 50%),
	'secondary-100': lighten($secondary, 40%),
	'secondary-200': lighten($secondary, 30%),
	'secondary-300': lighten($secondary, 20%),
	'secondary-400': lighten($secondary, 10%),
	'secondary-500': darken($secondary, 10%),
	'secondary-600': darken($secondary, 20%),
	'secondary-700': darken($secondary, 30%),
	'secondary-800': darken($secondary, 40%),
	'secondary-900': darken($secondary, 50%),
	'grey-50': lighten($grey, 50%),
	'grey-100': lighten($grey, 40%),
	'grey-200': lighten($grey, 30%),
	'grey-300': lighten($grey, 20%),
	'grey-400': lighten($grey, 10%),
	'grey-500': darken($grey, 10%),
	'grey-600': darken($grey, 20%),
	'grey-700': darken($grey, 30%),
	'grey-800': darken($grey, 40%),
	'grey-900': darken($grey, 50%),
);

// map merge bootstrap variables and custom theme colors
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../src/assets/font';

body {
	font-size: 1.1rem;
	font-family: 'AlsHaussRegular', sans-serif;
}

h1,
.fw-heavy,
.ff-gothic {
	font-family: 'TradeGothicNextCondensedHeavy', sans-serif;
}

h2,
.ff-gothic-bold {
	font-family: 'TradeGothicNextCondensedBold', sans-serif;
}

.ff-alshaus-bold {
	font-family: 'AlsHaussBold', sans-serif;
}

.w-85 {
	width: 80%;
	@media (max-width: 768px) {
		width: 100%;
	}
}

.fs-7 {
	font-size: 0.85rem !important;
}

.navbar-nav {
	--bs-nav-link-padding-y: 0.1rem;
	.nav-link {
		font-size: 1rem;
		@media (min-width: 768px) {
			&.one {
				border-bottom: solid 4px #738bff;
				padding-right: 0;
				padding-left: 0;
			}
			&.two {
				margin-right: 20px;
				margin-left: 20px;
				border: solid 2px $dark;
				border-radius: 6px;
				padding: 3px 10px;
				&:hover {
					background: $light;
				}
			}
			&.three {
				&:hover {
					opacity: 0.8;
				}
			}
		}
		@media (max-width: 768px) {
			&.two {
				margin-top: 10px;
				margin-bottom: 15px;
			}
		}
	}
}

.dashboard {
	font-size: 0.95rem;
	.navbar {
		--bs-navbar-padding-y: 0.9rem;
		--bs-navbar-nav-link-padding-x: 0.75rem;
		.navbar-brand img {
			width: 150px;
			height: auto;
		}
		.navbar-nav {
			--bs-nav-link-padding-y: 0.4rem;
			.nav-link {
				&.active {
					border-bottom: solid 3px $primary;
					&:hover {
						background: transparent;
						border-radius: 0;
					}
				}
				&:hover {
					background-color: rgb(var(--bs-light-rgb));
					border-radius: 18px;
				}
			}
		}
	}
	@media (min-width: 992px) {
		.navbar-expand-lg .navbar-nav .nav-link {
			padding-right: var(--bs-navbar-nav-link-padding-x);
			padding-left: var(--bs-navbar-nav-link-padding-x);
		}
	}
	// .table {
	// 	--bs-border-opacity: 1;
	// 	border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
	// }
	.offcanvas.offcanvas-end {
		top: 80px;
		border: solid 1px #efefef;
		box-shadow: -2px 0px 3px 0px #c3c3c3;
		border-radius: 6px;
	}
	.offcanvas-backdrop {
		background-color: transparent !important;
	}
}

.navbar-toggler {
	border: none;
	outline: none;
	&:focus {
		outline: none;
		box-shadow: 0 0 0 0;
	}
}

// Custom styles
.navbar-brand img {
	width: 300px;
	height: auto;
	@media (max-width: 768px) {
		width: 180px;
	}
}
 
.custom-tab-item {
	margin-right: 1rem;
  
	&.disabled {
	  opacity: 0.5;
	  pointer-events: none;
	  cursor: not-allowed;
	}
  }
  
  .custom-tab-link {
	background: none;
	border: none;
	color: #000;
	padding: 0.5rem 1rem;
	cursor: pointer;
	position: relative;
	font-weight: 500;
	outline: none;
  
	&:hover {
	  color: #0056b3;
	}
  }
  
  .custom-tab-link.active {
	color: #007bff; 
  }
  
  .custom-tab-link.active::after {
	content: '';
	display: block;
	width: 100%;
	height: 2px;
	background-color: #007bff; 
	position: absolute;
	bottom: 0;
	left: 0;
  }
  
  .badge.bg-primary {
	background-color: #007bff; 
  }
.display-1 {
	font-size: 96px;
	line-height: 1.15 !important;
}
.display-6 {
	font-size: 42px;
}

.header {
	.top-n70 {
		top: -90px;
		@media (max-width: 768px) {
			top: 0;
		}
	}
	.top-n20 {
		top: -20px;
		z-index: 2;
		@media (max-width: 768px) {
			top: 0;
		}
	}
	span.hero-underline-stroke {
		position: relative;
		img {
			position: absolute;
			bottom: -10px;
			left: -16px;
			width: 104%;
			z-index: 2;
			@media (max-width: 768px) {
				bottom: -5px;
			}
		}
	}
	span.hero-circle-stroke {
		position: relative;
		img {
			position: absolute;
			top: -21px;
			left: -24px;
			width: 114%;
			z-index: 2;
			@media (max-width: 768px) {
				top: -10px;
				left: -5px;
			}
		}
	}
}

span.underline-stroke {
	position: relative;
	img {
		position: absolute;
		bottom: -15px;
		left: 0;
		z-index: 2;
		@media (max-width: 768px) {
			width: 205px !important;
			bottom: -10px;
		}
	}
}

.blurbs {
	h2 {
		position: relative;
		&::before {
			position: absolute;
			top: 10px;
			left: -70px;
			font-size: 140px;
			line-height: 1rem;
			color: #b0bcff;
			font-family: 'TradeGothicNextCondensedHeavy', sans-serif;
			@media (max-width: 768px) {
				display: none;
			}
		}
		&.one::before {
			content: '1';
		}
		&.two::before {
			content: '2';
		}
		&.three::before {
			content: '3';
		}
	}
	li {
		position: relative;
		list-style: none;
		&::before {
			position: absolute;
			top: 10px;
			left: 0;

			font-size: 60px;
			line-height: 1rem;
			color: #b0bcff;
			font-family: 'TradeGothicNextCondensedHeavy', sans-serif;
		}
		&.one::before {
			content: '1';
		}
		&.two::before {
			content: '2';
		}
		&.three::before {
			content: '3';
		}
	}
	@media (max-width: 768px) {
		.img-fluid {
			width: 70%;
		}
	}
}

.text-lighter {
	color: #738bff !important;
}
.bg-dark-gradient {
	background: rgb(5, 11, 51);
	background: linear-gradient(0deg, rgba(5, 11, 51, 1) 0%, rgba(19, 5, 107, 1) 100%);
}

.bg-light-gradient {
	background: #b0bcff;
	background: linear-gradient(0deg, #e4e8ff 0%, #b0bcff 100%);
}

.lead-form {
	.form-select,
	.form-control.border,
	.form-check-input[type='radio'],
	.form-check-input[type='checkbox'] {
		border-color: #cad1f7 !important;
	}
}

.form-select,
.form-control.border {
	//border-color: #cad1f7 !important;
	&:focus {
		border-color: $primary !important;
		box-shadow: 0 0 0 0.25rem transparent !important;
		outline: none !important;
	}
}

.floating-icon {
	top: 32px;
}

.form-label {
	font-size: 14px;
	color: $dark;
}
.form-control:not(textarea):not(.search-input) {
	padding: 0.55rem 0.75rem;
	height: calc(2.8rem + 3px);
	min-height: calc(2.8rem + calc(var(--bs-border-width) * 2));
}
.form-check-input[type='radio'] {
	border-radius: 50%;
	border-style: solid;
	border-width: 2px;
}
.form-check-input[type='checkbox'] {
	border-style: solid;
	border-width: 2px;
}
.form-check-input:checked {
	border-color: #4b57e7 !important;
}
.form-custom-checkbox {
	margin-right: 15px;
	&.form-check-input {
		transform: scale(1.5);
		outline: solid 1.2px #4b57e7;
		cursor: pointer;
		&:checked {
			border: solid 1.4px #dbddfa !important;
			background-color: #1fc29c;
			padding: 2px;
		}
	}
}
.form-control[required]:focus {
	border-color: $danger !important;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled {
	// color: #ccc;
	display: none;
}

.react-datepicker__tab-loop {
	display: inline-block !important;
}

.dashboard {
	.form-control:not(textarea) {
		height: calc(2rem + 2px);
		min-height: calc(2.6rem + var(--bs-border-width) * 2);
	}
}

.accordion {
	--bs-accordion-color: #4b57e7;
	--bs-accordion-btn-icon-width: 1.85rem;
	--bs-accordion-active-color: #4b57e7;
	--bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='blue'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	--bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='blue'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	.accordion-header button {
		font-size: 24px;
		font-family: 'AlsHaussRegular', sans-serif;
	}
}

.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .dropdown-menu,
.table-responsive .btn-group-vertical {
	position: absolute !important;
}

@media (min-width: 768px) {
	.table-responsive {
		overflow: visible !important;
	}
}

::placeholder {
	opacity: 0.5; /* Firefox */
}

::-ms-input-placeholder {
	/* Edge 12 -18 */
	color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
	--bs-backdrop-zindex: 997;
}

@media (max-width: 768px) {
	br {
		display: inline !important;
	}
	.w-50,
	.w-75 {
		width: 100% !important;
	}
	.header {
		.display-1 {
			font-size: calc(1.85rem + 3.5vw);
		}
		.display-3 {
			font-size: calc(1.3rem + 1.5vw);
		}
		.display-5 {
			font-size: calc(1.2rem + 1vw);
		}
	}
	.display-6 {
		font-size: calc(1.2rem + 1vw);
	}
}

@media (max-width: 480px) {
	.display-1 {
		font-size: calc(1.85rem + 3.5vw);
	}
	.charthead {
		h3 {
			font-size: 18px;
		}
		p {
			font-size: 10px;
		}
	}
}

// JobCard
.JobCard li {
	position: relative;
}

.JobCard li::before {
	content: '';
	position: absolute;
	top: 8px;
	left: -12px;
	width: 18px;
	height: 18px;
	line-height: 18px;
	text-align: center;
	border-radius: 50%;
	background-color: $primary;
	color: $light;
	font-size: 10px;
}

.JobCard li::after {
	content: '';
	position: absolute;
	top: calc(8px + 18px);
	left: -4px;
	width: 2px;
	height: calc(100% - 18px);
	background-color: $primary;
}

.JobCard__item--completed::before {
	content: '✓' !important;
}

.JobCard__item--disabled::before,
.JobCard__item--disabled::after {
	background-color: #e3e3e3 !important;
}

.JobCard__main-content::before {
	content: '';
	position: absolute;
	left: -14px;
	top: 19px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 7px 14px 7px 0;
	border-color: transparent;
}

// Misc
.cursor-pointer {
	cursor: pointer !important;
}

.small-button {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 0px;
	padding-bottom: 0px;
	// height: 30px;
}
.left-portion-option {
	padding-left: 20px;
}
.option-left {
	height: 20px;
}
.sub-option-left {
	margin-left: 30px;
}
.header-footer {
	height: 30px;
}
.sub-header-footer {
	background-color: #eeeeee;
}

.RegisterPage {
	min-height: 1000px !important;
}

.LeadForm--registration {
	position: absolute;
	top: -300px;
	@media (max-width: 768px) {
		position: relative;
		top: 0px;
	}
}
.sub-header-footer {
	background-color: #eeeeee;
}
.b-start {
	border-left: 0.6px solid $info;
}
.chat-list-side {
	display: block;
}
.chat-list-button {
	display: none;
}
.chevron-double {
	right: 30px;
}
.image-message {
	width: 260px;
	height: 250px;
	border-radius: 10px;
	margin: 7px;
	box-sizing: border-box;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	object-fit: cover;
}
.chats {
	width: fit-content;
	max-width: 70%;
	p {
		margin-bottom: 15px !important;
		&:last-child {
			margin-bottom: 0px !important;
		}
	}
}
.chats-container {
	min-height: 44vh;
	// overflow-y: auto;
	display: flex;
	flex-direction: column-reverse; /* Display flex items in reverse order */
}
.chats-area {
	display: flex;
	flex-direction: column;
}
.send-button {
	display: none;
}
.chatback-button {
	width: 35px;
	height: 35px;
	border-radius: 100px;
	margin-left: -20px;
	background-color: #fff;
	border: 0.7px solid '#ababab';
	margin-top: -5px;
}
.chatback-button:hover {
	background-color: #fff;
}
.offline-Button {
	background-color: $grey;
}
.rdw-link-modal {
	position: fixed !important;
	top: 60vh !important;
	left: 40% !important;
}
.userlist .mini-font {
	font-size: 0.7em;
}
.chat-input {
	width: 100%;
	font-size: 0.9em;
	min-height: 100px;
	max-height: 400px;
	border-radius: 25px;
	overflow-y: auto;
	outline: none;
	padding: 10px;
}
.demo-wrapper {
	height: 80px;
}
.chat-input-filed:focus {
	outline: none;
}

.small-text {
	font-size: 0.8em;
}
.chat-item {
	border-radius: 13px;
}
.chat-Box {
	min-height: 81vh;
	max-height: 81vh;
}
.isType-text {
	font-size: 0.7em;
	color: #818181;
	margin-left: 10px;
}
.chat-user-scrollbar {
	height: 88% !important;
}
.isTypingArea {
	height: 25px;
	padding-bottom: 5px;
}
.rdw-link-modal {
	top: -180px !important;
	height: 250px !important;
}
.rdw-editor-toolbar {
	border-width: 0px !important;
	background-color: var(--bs-white-rgb) !important;
}
.public-DraftEditor-content {
	margin-top: -25px !important;
	// height: 80px !important;
	padding-top: 10px !important;
}
.message-content > p > a {
	color: $secondary !important;
}
.delete-icon {
	// display: none;
	visibility: hidden;
}
.animation-div {
	position: relative;
	margin-top: 0px;
	margin-left: 50px;
	margin-bottom: -5px;
}
.image-container {
	cursor: pointer;
	height: 70px;
}
.image-container:hover .delete-icon {
	visibility: visible;
}
.upload-image {
	width: 60px;
	height: 55px;
	object-fit: fill;
	border: 0.5px solid $primary;
}
.circle-animation {
	width: 20px;
	height: 20px;
	animation: spin 3s linear infinite;
	margin-left: 50px;
	border: 1.5px solid $primary;
	border-radius: 50px;
}
.overflow-y {
	overflow-y: scroll;
}
.chat-offCanvas {
	padding-top: 20px;
}
// Custom width Classes
.w-200 {
	width: 200px;
}

.rdw-editor-main {
	max-height: 70px !important;
}
.public-DraftEditor-content {
	padding: 10px !important;
}
.public-DraftEditorPlaceholder-root {
	padding-top: 5px !important;
	padding-left: 10px;
}
.rdw-link-modal-btn {
	background-color: var(--bs-primary) !important;
	border-color: var(--bs-primary) !important;
}
.rdw-link-modal {
	position: fixed !important;
	top: 60vh !important;
	left: 40% !important;
	background-color: rgb(var(--bs-white-rgb)) !important;
	border: solid 1px rgb(var(--bs-white-rgb)) !important;
}
.ScrollbarsCustom-Thumb {
	width: 7px !important;
}
.ScrollbarsCustom-Track {
	width: 7px !important;
}
.search-icon {
	margin-top: 13px;
	margin-left: 8px;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 940px) {
	.icon-div {
		width: 200px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: space-around;
		flex-wrap: wrap;
		height: 80px;
	}
	.video-appointment-height {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-around;
		height: 80px;
	}
	.video-appointment {
		width: 140px;
		font-size: 10px;
		margin: 0px !important;
	}
	.send-button {
		display: block;
	}
	.chats-container {
		min-height: 48vh;
	}
	.delete-icon {
		visibility: visible !important;
	}
	.chats {
		max-width: 90%;
	}
	.image-message {
		width: 200px;
		height: 200px;
	}
	.chat-Box {
		min-height: 77vh !important;
		max-height: 77vh !important;
	}
	.chat-list-side {
		display: none;
	}
	.chat-list-button {
		display: flex;
		width: 97%;
		height: 50px;
		margin-left: 1%;
		margin-bottom: 20px;
	}
}

// Navbar custom search field stylings
.Navbar__search {
	width: 40px;
	transition: width 0.3s;
	position: relative;
}

.Navbar__search .form-control {
	width: 0;
	opacity: 0;
	transition: width 0.3s, opacity 0.3s;
}

.Navbar__search svg {
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	cursor: pointer;
}

.Navbar__search--active {
	width: 200px;
}

.Navbar__search--active .form-control {
	width: 100%;
	opacity: 1;
}

.Navbar__search--active svg {
	display: none;
}

// General
.min-height-initial {
	min-height: initial !important;
}

// Calendar
.react-calendar__navigation__label {
	font-weight: bold !important;
	font-size: 20px !important;
}

.react-calendar__tile {
	font-size: 12px;
}

.react-calendar__tile--now {
	background-color: var(--bs-primary) !important;
	color: var(--bs-white) !important;
}

.react-calendar__month-view__weekdays__weekday {
	font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday * {
	text-decoration: none !important;
}

.BookAppointmentForm__calendar {
	.react-calendar__tile--now,
	.react-calendar__month-view__days__day--weekend,
	.react-calendar__month-view__days__day--neighboringMonth {
		color: rgba(16, 16, 16, 0.3) !important;
	}
	.react-calendar__tile {
		height: 47px !important;
		border-radius: 50% !important;
	}
	.react-calendar__tile:disabled {
		background-color: #fff !important;
	}
}

.react-pdf__Page__canvas {
	margin: auto;
}
.select__control {
	height: 42px !important;
}

.custom-left-form {
	margin-left: -25px;
}
.custom-loader {
	background-color: rgb(188, 188, 188, 0.3);
	z-index: 99999 /* Make sure it appears on top */;
}

.react-pdf__Page {
	z-index: 0;
}

.disabled {
	pointer-events: none;
	opacity: 0.6; /* You can adjust the opacity to give a visual indication of being disabled */
}

.appointment-form__calendar {
	.react-datepicker__time-container {
		width: 50%;
	}

	.react-datepicker__time {
		width: 100%;
		height: 100%;
	}

	.react-datepicker__time-box {
		width: 100% !important;
	}

	.react-datepicker__time-list {
		margin: 10px;
	}

	.react-datepicker__time-list::-webkit-scrollbar {
		width: 7px;
	}

	.react-datepicker__time-list::-webkit-scrollbar-track {
		background: rgba(var(--bs-light-rgb));
		border-radius: 7px;
	}

	.react-datepicker__time-list::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 7px;
	}

	// .react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
	// 	background: rgba(var(--bs-black-rgb));
	// }

	.react-datepicker__day--selected,
	.react-datepicker__time-list-item--selected {
		background-color: var(--bs-primary) !important;
		color: var(--bs-white) !important;
	}

	.react-datepicker__header {
		background-color: var(--bs-white);
		border: 0;
	}

	.react-datepicker__header--time {
		display: none;
	}

	.react-datepicker__day-name,
	.react-datepicker__day {
		width: 2.5rem;
		line-height: 2.2rem;
	}

	.react-datepicker__time-container {
		border: 0;
	}
}
// ID Input Container
.IdContainer {
	min-height: 180px;
	max-height: 180px;
	object-fit: fill;
	padding: 0%;
	width: 100%;
}
// Dark mode specific styles
[data-bs-theme='dark'] {
	.message-content.bg-dark {
		background-color: #4f665d !important;
		color: #fff !important;
	}
	.css-13cymwt-control {
		background-color: transparent !important;
		border-color: rgb(var(--bs-white-rgb)) !important;
		color: #fff !important;
	}
	.css-1mndiq5-menu {
		//background-color: rgb(var(--bs-white-rgb)) !important;
		color: #000 !important;
	}
}

// React-select
#react-select-2-placeholder {
	color: rgb(var(--bs-black-rgb));
}


/// Input - Time Picker styles

  input[type=time] {
	display: inline-block;
	position: relative;
  }
  
  input[type=time]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
  }

.banner-background{
	height: 200px;
	background-image: url('./assets//img/billing.png');
}
.h-300{
	height: 300px;
}
.h-270{
	height: 270px;
}


// Dialpad Styles

#button-hangup-incoming{
	display: inline-block;
	background-color: #ec2f0e;
	padding: 4px 30px;
	margin: 10px;
	color: white;
	border-radius: 4px;
	cursor: pointer;
}

#button-hangup-incoming{
	display: inline-block;
	background-color: #ec2f0e;
	padding: 4px 30px;
	margin: 10px;
	color: white;
	border-radius: 4px;
	cursor: pointer;
}

button:disabled {
	cursor: not-allowed;
}

#phone-number{
	width: 96%;
	margin-bottom: 20px;
	border: 0;
	outline: 0;
	border-radius: 0px;
	height: 62px;
	box-shadow: inset 0 0 24px -1px #EEEEEF;
	font-size: 23px;
	padding: 0 10px;
	direction: rtl;
	color: #bcbcbc;
	display: none;
}
.pad-row {
	margin: 0 auto;
	// width: 280px;
	clear: both;
	text-align: center;
	font-family: 'Exo';
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin-bottom: 5px;
}
.digit, .dig {
	// padding: 10px 30px;
	width: 55px;
	height: 55px;
	font-size: 1.3rem;
	cursor: pointer;
	color: rgb(199 199 199);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 1px 7px -6px rgb(0, 0, 0);
	border-radius: 100%;
	
}
.successText{
	color: $successLight;
}
.sub {
	font-size: 0.8rem;
	color: grey;
	// display: none;
}
.pad-container {
	background-color: rgb(255, 255, 255);
	display: flex;
	justify-content: center;
	width: 250px;
	padding-top: 30px;
	padding-bottom: 30px;
	// padding: 20px;
	margin: 30px auto;
	// height: 420px;
	text-align: center;
	// box-shadow: 4px 14px 1px rgb(250 250 250 / 25%), 1px 5px 22px rgb(125 125 125 / 22%);
	border-radius: 14px;
}
	#hangup {
		display: inline-block;
		background-color: #dc3545;
		padding: 10px 30px;
		display: flex;
		border-radius: 4px;
		justify-content: center;
		align-items: center;
		color: white;
		cursor: pointer;
		margin-top: 10px;
	}
	.botrow {
		margin: 0 auto;
		width: 100%;
		clear: both;
		text-align: center;
		font-family: 'Exo';
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.digit:active, .dig:active  {
		background-color: #e9e9e9;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center	;
	}
	.dig:active .sub, .digit:active .sub{
		display: none;
		border-radius: 50%;
		background-color: red;
	}
	#button-call:hover {
		background-color: #81c784;
	}
	#hangup:hover {
		background-color: #c82b3b;
	}
	.dig {
		float: left;
		padding: 10px 20px;
		margin: 10px;
		width: 30px;
		cursor: pointer;
	}

	.hidescroll::-webkit-scrollbar {
		display: none;
	}
	.cutomBorderBox{
		border-style: dashed;
		background-color: #fafaff;
	}

	.stepContainer>.node{
		height: 21px;
		margin-top: 2px;
		margin-bottom: 1px;
	}
	.custom-membership-card{
		min-height:1150px !important;	
		height:1150px !important;
	}
	.card-nested{
		width:90%;
		margin-left:10%
	}

	.bottom-17 {
		bottom: -17px !important;
	}
	.bg-hover:hover{
		background-color: #e8e7e7;
	}
	.calendar-icon{
		position: absolute;
		top: 15px;
		left: 20px;
		z-index: 995;
	}

	.dollar-icon{
		position: absolute;
		top: 8px;
		left: 20px;
		z-index: 999;
		font-size: 20px;
	}
	.border-dashed {
		border: 1px dashed #b1b1b1;
	}
	.border-solid {
		border: 1px solid #b1b1b1;
	}
	.card-cont{
		padding-left: 140px;
		padding-right: 140px;

	}
	.card-request-cont{
		padding-left: 30px;
		padding-right: 30px;

	}
	.custom-card {
		width: 30%;
		margin: 10px; 
		height: 140px;
		cursor:pointer;
	}
	.custom-card  .delete {
		height: 0px;
		transition: all 0.3s;
	}
	.custom-card:hover  .delete {
		height: 25px;
	}
	.custom-request-card {
		width: 300px;
		margin: 10px; 
		height: 150px;
		cursor:pointer;
	}

	@media screen and (max-width:768px) {
		.icon{
			height: 20px;
		}
		.custom-card {
			width: 100%;
		}
		.card-cont{
			padding: 0 0;
			width: 100%;
		}
	}
	.max-w{
		max-width:660px
	}
	.icon-h{
		height: 40px;
	}

	.bg-color{
		background-color: #FAFAFF;
	}
	.border-b{
		border-bottom: 1px solid #d8d8d8;
	}

.note-has-grid .nav-link {
    padding: .5rem;
}

.note-has-grid .single-note-item .job-note-card {
    border-radius: 10px;
}

.note-has-grid .single-note-item .favourite-note {
    cursor: pointer;
}

.note-has-grid .single-note-item .side-stick {
    position: absolute;
    width: 3px;
    height: 35px;
    left: 0;
    background-color: rgba(82, 95, 127, .5);
}

.note-has-grid .single-note-item .category-dropdown.dropdown-toggle:after {
    display: none;
}

.note-has-grid .single-note-item .category [class*=category-] {
    height: 15px;
    width: 15px;
    display: none;
}

.note-has-grid .single-note-item .category [class*=category-]::after {
    content: "\f0d7";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 12px;
    color: #fff;
    position: absolute;
}

.note-has-grid .single-note-item .category .category-business {
    background-color: rgba(44, 208, 126, .5);
    border: 2px solid #2cd07e;
}

.note-has-grid .single-note-item .category .category-social {
    background-color: rgba(44, 171, 227, .5);
    border: 2px solid #2cabe3;
}

.note-has-grid .single-note-item .category .category-important {
    background-color: rgba(255, 80, 80, .5);
    border: 2px solid #ff5050;
}

.note-has-grid .single-note-item.all-category .point {
    color: rgba(82, 95, 127, .5);
}

.note-has-grid .single-note-item.note-business .point {
    color: rgba(44, 208, 126, .5);
}

.note-has-grid .single-note-item.note-business .side-stick {
    background-color: rgba(44, 208, 126, .5);
}

.note-has-grid .single-note-item.note-business .category .category-business {
    display: inline-block;
}

.note-has-grid .single-note-item.note-favourite .favourite-note {
    color: #ffc107;
}

.note-has-grid .single-note-item.note-social .point {
    color: rgba(44, 171, 227, .5);
}

.note-has-grid .single-note-item.note-social .side-stick {
    background-color: rgba(44, 171, 227, .5);
}

.note-has-grid .single-note-item.note-social .category .category-social {
    display: inline-block;
}

.note-has-grid .single-note-item.note-important .point {
    color: rgba(255, 80, 80, .5);
}

.note-has-grid .single-note-item.note-important .side-stick {
    background-color: rgba(255, 80, 80, .5);
}

.note-has-grid .single-note-item.note-important .category .category-important {
    display: inline-block;
}

.note-has-grid .single-note-item.all-category .more-options,
.note-has-grid .single-note-item.all-category.note-favourite .more-options {
    display: block;
}

.note-has-grid .single-note-item.all-category.note-business .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-business .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-important .more-options,
.note-has-grid .single-note-item.all-category.note-favourite.note-social .more-options,
.note-has-grid .single-note-item.all-category.note-important .more-options,
.note-has-grid .single-note-item.all-category.note-social .more-options {
    display: none;
}
.note-has-grid .single-note-item {
	max-width: 100%;
}

.custom-min-width {
    min-width: 100% !important; 
  }

  .file-upload {
    overflow: hidden !important;
  } 

.editor {
	position: relative;
}

.editor-overlay{
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background-color: rgb(233, 233, 233);
	opacity: 0.5;
	z-index: 100;
}
.editor-danger-text{
	display: flex;
	justify-content: center;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: transparent;
	z-index: 200;
	padding-top: 100px;
}


.blurred {
    filter: blur(5px);
    display: inline-block;
    cursor: not-allowed;
}

.edit-text{
	font-weight: 5px;
}
  .react-calendar__tile--now {
    background-color: #b5b9e6 !important;
    color: var(--bs-white) !important;
}
.custom-stepform-bg{
	background-color: #FAFAFF;
}
.custom-option-styles{
	color:#636a70;
	font-weight: 500;
}
.sub-option-progress-bar{
  height: 60px;
  width: 2px;
  background-color: #E4E8FF;
}
.arrow-right{
	transform: rotate(265deg);
	transition: 0.3s;
}
.arrow-down{
	transform: rotate(0deg);
	transition: 0.3s;
}
.custom-gray-text{
	color: #606468 !important;
}
.custom-gray-text label {
	color: #606468 !important;
}

.custom-form-check-input {
	position: absolute;
	opacity: 0;
  }
  
  .custom-form-check-input + .custom-radio-label {
	position: relative;
	cursor: pointer;
	padding-left: 45px;
  }
  
  .custom-form-check-input + .custom-radio-label::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 3px solid  #0003c0;; /* Custom border color */
	background: #ffffff; /* Background color */
  }
  
  .custom-form-check-input:checked + .custom-radio-label::after {
	content: '';
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #0003c0;; /* Custom circle color */
  }

  .custom-font {
	font-size: 18px;
	color: #343A40;
	font-weight: bold;
}
.custom-font-sub{
	font-size: 16px;
	color: #343A40;
	font-weight: lighter !important;
  }
  .left-line{
	width: 2px;
	background-color: #c1c6cb;
	height: 100%;
  }
  .custom-subsection-text{
	margin: 0 !important;
	font-size: 20px;
	padding: 0 20px;
  }
  .custom-sunsection-btn{
	font-size: 14px;
  }
  .btn-styles{
	height: 40px;
  }
  .custom-line{
	width: 100%;
	height: 2px;
	background-color: #d0d0d0;
	margin-bottom: 20px;
  }

  .section-title-styles{
	text-align: left;
	margin-top:10px ;
	margin-bottom: 3px;
	font-size: 25px;
	color: #676666;
	font-weight: 600;
  }
  .text-left{
	text-align: left !important;
  }
  
  .form-label {
	display: inline-block;
	min-width: 100px; /* Adjust this value as needed */
  }

  .editing-section{
	background:#fff3cd !important;
  }
  .dashed-border {
	border: 1px dashed #c7c7c7; /* 2px wide dashed border with black color */
  }
  .dynamic-form-card{
	position: relative;
  }
  .delete{
	position: absolute;
	right: -10px;
	top: -10px;
  }

  .sections-bg{
	background-color: #FAFAFF;
	padding: 15px;
  }

  .custom-modal-overlay{
	padding: 20px;
	background-color: rgba(54, 54, 54, 0.3);
	width: 100%;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 998;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
  }

.custom-modal-cont{
	max-width: 100%;
	width: 541px;
	height: 300px;
	border-radius: 5px;
	background-color: white;
	padding: 40px;
	animation: fadeInTop 0.2s ease-out
}
.custom-modal-cont-delete{
	max-width: 100%;
	width: 541px;
	height: 200px;
	border-radius: 5px;
	background-color: white;
	padding: 40px;
	animation: fadeInTop 0.2s ease-out
}
.custom-modal-cont-create-form{
	width: 100%;
	max-width: 1300px;
	height: auto;
	border-radius: 5px;
	background-color: white;
	padding: 40px;
	margin-top: 70px;
	top: 0px;
	margin-bottom: 50px;
	position: absolute;
	animation: fadeInTop 0.2s ease-out;
	@media (max-width: 1300px) {
		width: 95%;
	}
	.custom-modal-close-button {
		position: absolute;
		top: 35px;
    	right: -10px;
		cursor: pointer;
	}
}
@keyframes fadeInTop {
	0% {
	  opacity: 0.5;
	  transform: translateY(-20px); 
	}
	100% {
	  opacity: 1;
	  transform: translateY(0); 
	}
  }
.custom-modal-heading{
	text-align: left;
	color: #212529;
	font-size: 25px;
}
.custom-modal-description{
	color: #66696C;
	font-size: 16px;
	text-align: left;
}
.custom-modal-input-cont{
	display: flex;
	flex-direction: column;
	text-align: left;
}
.custom-modal-input-cont label{
	color: #212529;
	font-size: 20px;
}

.custom-modal-input-cont input {
	border: 1px solid #DEE2E6;
	outline: none;
	margin-top: 10px;
	padding: 10px 10px;
	border-radius: 8px;
}
.custom-modal-buttons{
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
.questionaire-search-input-cont{
	display: flex;
	align-items: center;
	border: 1px solid #c9c9c9;
	border-radius: 4px;
	padding: 5px 10px;
	gap: 5px;
	position: relative;
}
.questionaire-search-input{
	border: none;
	outline: none;
}

.search-questionaire-modal-cont{
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	width: 140%;
	height: auto;
	border-radius: 8px;
	box-shadow: 0 0 10px 1px rgb(238, 238, 238);
	background-color: white;
	z-index: 9999;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.form-title{
	border-radius: 4px;
	padding: 10px 10px;
	cursor: pointer;
}
.form-title:hover{
	background-color: rgb(241, 241, 241);
}

.border-cont{
	border: 1px solid rgba(241, 241, 241,0.8);
	border-radius: 8px;
}

.border-bottom{
	border-bottom: rgba(241, 241, 241,0.8) ;
	font-size: 16px;
}

.icon-dots{
	height: 15px;
	cursor:pointer;
}
.icon-dots-sub{
	height: 12px;
	fill: #0003c0;
	cursor:pointer;

}
.icon-delete{
	height: 25px;
	cursor: pointer;
}
.add-cuestions-cont{
	background: rgba(144, 147, 153, .05);
    border: 2px dashed rgba(144, 147, 153, .2);
    border-radius: 6px;
    margin: 12px 0 0;
    padding: 36px 15% 24px;
    text-align: center;
}

.add-question-title{
	color: #606266;
}
.add-question-description{
	color: #717377;
	font-size: 14px;
}

.add-question-btn-cont{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.add-question-btn{
	line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
}

.add-question-btn:hover{
    cursor: pointer;
    background: #bae2f18c;
}

.sub-section-cont{
	padding-left: 50px;
	padding-right: 15px;
}

.close-form-btn{
	line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
	border: 1px solid #4880f8;
	color: #4880f8;
}
.close-form-btn-disabled{
    border: 1px solid #dcdfe6;
    color: #606266;

}



.form-name{
	color:#4B57E7;
	font-size: 29px;
	font-weight: bold;
}
.left-bar-cont{
	border-radius: 12px;
	background-color: #FAFAFF;
	height: auto;
	margin-top: 30px;
	padding: 30px;
	position: sticky;
	top: 5%;
}

.section-title{
	color: #212529;
	font-size: 22px;
	font-weight: bold;
}

.sub-section-title{
	padding: 10px 40px;
	transition: 0.3s;
	cursor: pointer;
	margin-left: 7px;
	color: #838383;
	font-size: 20px;
	font-weight: bold;
	border-left: 1px solid #E4E8FF;
}

.sub-section-title:hover{
	border-left: 1px solid #4B57E7;
	color:#4B57E7;
}
.sub-section-title-active{
	color:#4B57E7;
}

.sub-section-filled{
	border-left: 1px solid #4B57E7;
}

.arrow-down{
	transform: rotate(0deg);
}
.arrow-right{
	transform: rotate(-90deg);
}

.custom-modal-heading-add-question{
	text-align: center !important;
	font-size: 22px !important;
}
.custom-modal-cont-add-question{
	width: 100%;
	max-width: 850px;
	background-color: white;
	padding: 30px;
	border-radius: 7px;
	min-height: 500px;
	animation: fadeInTop 0.2s ease-out;
	top: 30px;
    margin-bottom: 50px;
    position: absolute;
}
.Question-text-input{
	border: 1px solid #d1d1d1;
	outline: 0;
	padding: 10px 20px;
	border-radius: 7px;
	width: 300px;
}

.form-heading{
	font-size: 28px;
	padding: 0 10px;
	color: #212529;
	font-weight: bold;
}
.form-questoins-cont{
	border: 1px solid rgba(226, 226, 226, 0.726);
	border-radius: 10px;
	margin-top: 20px;
}
.form-seub-section-title{
	color: #212529;
	font-size: 24px;
	border-bottom: 1px solid  rgba(226, 226, 226, 0.726);
	padding: 20px;
	font-weight: bold;

}
.section-divider{
	padding: 20px 20px;
	border-bottom: 1px solid rgba(226, 226, 226, 0.726);
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dots-icon {
	margin-right: 10px;
	cursor: pointer;
	height: 15px;
}
.delete-section-icon {
	cursor: pointer;
	height: 23px;
}

.question-input{
	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	padding: 5px 5px;
	border-radius: 7px;
}
.question-input-list{
	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	padding: 2px 2px;
	border-radius: 7px;
}

.question-input-form{
	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	padding: 10px 10px;
	border-radius: 7px;
}
.css-13cymwt-control{
	border: 1px solid rgba(226, 226, 226, 0.726) !important;
}
.question-textarea {
	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	padding: 10px 20px;
	border-radius: 7px;
}
.divider-title{
	font-size: 18px;
	font-weight: 600;
}

.customer-form-section-title{
	color: #212529;
	font-size: 22px;
	font-weight: bold;
}

.customer-form-subsection-title{
	color: #3d4146;
	font-size: 18px;
	font-weight: bold;
	padding: 20px 20px;
	border-bottom: 1px solid rgba(226, 226, 226, 0.726);

}

.segment-header{
	color: #424547;
	font-size: 22px;
	font-weight: bold;
}
.customer-form-input-cont{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 20px;
}

// .customer-form-input-cont label, .customer-form-input-cont textarea{
// 	color:#343A40;
// 	font-size: 20px;
// 	font-weight: 500;
// }

// .customer-form-input-cont input{
// 	color:#343A40;
// 	border: 1px solid rgba(226, 226, 226, 0.726);
// 	border-radius: 8px;
// 	padding: 10px 20px;
// 	outline-color: #0003c0;
// }
.sections-cont{
	height: 75vh;
	position: sticky;
	top: 5%;
}

.sections-cont::-webkit-scrollbar {
    width: 8px;
}

.sections-cont::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sections-cont::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 4px;
}

.sections-cont::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
}

.ant-cascader-menus {
	z-index: 2000 !important; 
  }

  .ant-cascader{
  	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	width: 300px;
	border-radius: 7px;
  }
  .ant-select-selector{
	padding: 20px 20px !important;
  }

  .amount-input-cont{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(226, 226, 226, 0.726);
	outline: 0;
	border-radius: 7px;
  }
  .amount-input-cont input {
	padding: 10px;
	border: none;
	outline: none;
	text-align: right;
  }
  
 
 
 .amount-input-cont input::-webkit-outer-spin-button,
 .amount-input-cont input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
 }
 .question-input::-webkit-outer-spin-button,
 .question-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
 }
 .input-cont::-webkit-outer-spin-button,
 .input-cont::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
 }

 .form-control::-webkit-outer-spin-button,
 .form-control::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
 }
  .amount-icon-cont{
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #DEE2E6;
	padding: 10px 20px;
	color: #ADB5BD;
	}
	
	.yes-no-cont {
		border: 1px solid #4B57E7;
		border-radius: 5px;
		padding: 5px 10px;
		color: #4B57E7;
		cursor: pointer;
	}
	.yes-no-cont-active{
		background-color: #4B57E7;
		color: white;
		cursor: pointer;
	}

	.input-cont{
		width:300px,
	}
	.input-cont-form{	
		width: 48%;
	}
	.question-cont{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 20px;
	}
	.question-cont-form{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0px;
	}
	.add-option-title {
		color: rgb(4, 136, 85);
		text-align: end;
	}
	.option-cont{
		padding: 5px 10px;
		background-color: #5b6c7e;
		border-radius: 3px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
	}
	.option-delete-icon{
		border-radius: 50%;
		border: 1px solid rgb(255, 255, 255);
		color: rgb(255, 255, 255);
		padding: 0px 2px;
		font-size: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.3s;
	}

	.option-delete-icon:hover{
		transform: scale(1.2);

	}
	.dependents-form{
		margin: 40px 20px ;
		border: 1px solid #e0e0e0;
		border-radius: 6px;
		padding: 20px;
		
	}
	.dependent-header{
		font-weight: bold;
		font-size: 18px;
	}
	.add-dependent-btn{
		line-height: 1;
		white-space: nowrap;
		cursor: pointer;
		background: #fff;
		border: 1px solid #dcdfe6;
		color: #606266;
		text-align: center;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		transition: .1s;
		font-weight: 500;
		padding: 12px 20px;
		font-size: 14px;
		border-radius: 4px;
	}
	.dependant-form-divider{
		padding-bottom:10px;
		border-bottom: 1px solid rgb(223, 223, 223);
		font-weight: bold;
		font-size: 16px;
	}
	.list-columns th{
		font-weight: bold;
		font-size: 14px;
	}
	.dependant-rows td{
		color: gray;
	}
	.income-sources-cont{
		padding: 10px 20px;
	}
	.income-sources-cont-title{
		font-size: 18px;
		font-weight: bold;
		color: #212529;
	}
	.income-sources-cont-subtitle{
		margin-top: 20px;
		color: #343A40;
	}
	.income-sources-cards-cont{
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-top: 20px;
	}
	.income-sources-card{
		flex: 1 1 calc(25% - 20px);
		border-radius: 10px;
		border: 1px solid #E4E8FF;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 20px;
		text-align: center;
		gap: 10px;
		color: #212529;
		font-weight: 600;
		font-size: 16px;
		cursor: pointer;
		transition: 0.3s;
		transition: filter 0.3s ease;
	}
	.income-sources-card:hover{
		border: 1px solid #4B57E7;
		background-color: #4b58e70a;
	}
	.income-sources-card-active{
		border: 1px solid #4B57E7;
		background-color: #4b58e70a;
	}
	.income-svg-card:hover + .income-source-icon path {
		stroke: #FF0000; /* New stroke color on hover (red) */
		fill: #00FF00;   /* New fill color on hover (green) */
	  }
	  .income-sources-card:hover .income-source-icon,.income-sources-card:hover .expenses-select-icon {
		filter: brightness(0) saturate(100%) invert(23%) sepia(28%) saturate(2199%) hue-rotate(198deg) brightness(99%) contrast(92%);
	  }

	  .income-sources-card-active .income-source-icon, .income-sources-card-active .expenses-select-icon {
		filter: brightness(0) saturate(100%) invert(23%) sepia(28%) saturate(2199%) hue-rotate(198deg) brightness(99%) contrast(92%);
	  }
	.custom-switch {
		position: relative;
		display: inline-block;
		width: 45px;
		height: 24px;
	  }
	  
	  .switch-input {
		opacity: 0;
		width: 0;
		height: 0;
	  }
	  
	  .switch-label {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		border-radius: 34px;
		transition: 0.4s;
	  }
	  
	  .switch-label::before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		border-radius: 50%;
		left: -4px;
		bottom: 0px;
		background-color: white;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Similar shadow effect */
		transition: 0.4s;
	  }
	  
	  .switch-input:checked + .switch-label {
		background-color: #4B57E7; /* Change background color when checked */
	  }
	  
	  .switch-input:checked + .switch-label::before {
		transform: translateX(26px); /* Slide effect */
	  }
	  .edit-icon{
		height: 13px;
		margin-bottom: 5px;
	  }
	 .condition-cont{
		border: 1px solid #D48B22;
		margin-top: 10px;
		background-color:#e09d4025;
		border-radius: 6px;
	 }
	 .btn-pending{
		background-color: gray	;
		border: 0;
		padding: 10px 20px;
		color: white;
		font-weight: 600;
		border-radius: 5px;
	 }

	 .grid-header, .grid-row {
		display: flex;
		align-items:center;
		justify-content: space-between;
		padding: 0 20px;
	  }
	  .grid-header{
		gap: 10px;
	  }
	  
	  .grid-header-item, .grid-column {
		flex: 1;
	  }
	  
	  .grid-row-label {
		text-align: left;
	  }
	  
	.grid-question{
		color: #212529;
		font-family: 600;
		font-size: 18px;
		font-weight: 600;
	}
	.grid-input-cont{
		max-width: 200px !important;
	}
	.condition-select{
		max-width: 150px !important;
	}

	.table-cont th {
		color: #333; 
 	    background-color: #f8f9fa;
	  }

	.dependant-amount-input{
		width: 48%;
	}
	.amount-percentage-input-cont{
		width: 48%;
	}
	.action-icon-cont{
		border: 1px solid #4B57E7;
		border-radius: 50%;
		padding: 2px  7px;
		cursor: pointer;
	}
	.file-open-icon-cont{
		border: 1px solid #4B57E7;
		border-radius: 50%;
		padding: 2px  3px;
		cursor: pointer;
	}

	.grid-amount{
		color: #9c9c9c;
	}
	.table-scroll-container {
		overflow-x: auto; 
	  }
	  .table-scroll-container .row {
		display: flex;
		flex-wrap: nowrap; 
		padding: 0 10px;
	  }
	  
	  .table-scroll-container .col {
		flex: 1 1 auto; 
		min-width: 150px; 
		padding: 5px;
	  }
	  .del-row-icon {
		position: sticky;
		right:-2px;
		top:0 !important;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
	  }

	  .del-file-upload img {
		height: 30px !important;
		transition: all 0.3s;
	  }
	  .custom-progress-styles{
		min-width: 200px;
	  }
	
	  .file-upload-custom-styles{
		height: 50px !important;
		padding: 10px 0;
		font-size: 20px !important;
		// max-width: 250px !important;
	  }
	  .file-upload-custom-styles span{
		font-size: 11px !important;
	  }

	  .document-select{
		width: 100px !important;
		font-size: 15px;
	  }
	  .deductions-list {
		list-style-type: disc; 
		display: flex;
		flex-direction: column;
		justify-content: center;
	  }
	  
	 .deductions-list li::marker {
		font-size: 1.8em; 
		color:#212529; 
	  }

	  .deductions-list li {
		font-size: 1.1em;
		padding-top: 0;
		margin-top: 0;
	  }

	  .deductions-cont{
		padding: 10px 20px;
	  }
	  .horizontal-line{
		margin-top: 30px !important;
		margin-bottom: 30px !important;
	  }
	  .horizontal-hr{
		width: 100%;
		height: 1px;
		background-color: #c4c4c4;
	  }
	  .m-t-10{
		margin-top: 10px

	  }
	  .tagContainer {
		display: inline-flex;
		align-items: center;
		background-color: #e0e0e0;
		border-radius: 10px;
		padding: 4px 8px;
		font-size: 14px;
		color: #333;
		margin-top:10px;
		margin-right:2px;
	  }
	  .labelSuggestion {
		margin-right: 8px;
	  }
	  .closeButton {
		background: none;
		border: none;
		cursor: pointer;
		font-size: 16px;
		color: #333;
	  }

	.propose-milestone-cont{
		position: relative;
	}
	.plus-icon{
		position: absolute;
		left: -17px;
		top: 0;
		background-color: #E4E8FF;
		z-index: 10;
		font-weight: bold;
	}
.custom-datepicker-popper {
	z-index: 1360 !important;
}
.add-milestone-z-index{
	z-index: 1000 !important;
}
.file-icon {
	height: 31px !important;
}

.milestone-message-border{
	border-left: 5px solid #B0BCFF;
	background-color: #E1F0F6;
	border-radius: 10px;
	padding: 10px 10px;
}
.milesstone-message-bg {
	background-color: #E1F0F6;
	width: 100%;
	color: black;
	padding: 10px 10px;
	border-radius: 10px;
}

.bg-message{
	background-color: #E1F0F6;
}

.message-width {
	min-width: 550px !important;
}

@media (max-width: 768px) {
	.message-width {
		min-width: 350px !important;
	}
}

@media (max-width: 480px) {
	.message-width {
		min-width: 100% !important;
	}
}
.milesstone-message-bg .d-flex p {
	white-space: nowrap;
}

.height-100{
	height: 100px !important;
}

.question-input.is-invalid.css-b62m3t-container {
	border-color: red !important;
}
.height-100{
	height: 100px !important;
}
.message-innerBox{
	border-left: 4px solid #B0BCFF;
}
.z-max {
	z-index: 9999 !important;
}

.modal-content {
	padding: 25px;
	border: var(--bs-modal-border-width) solid rgb(170 219 211 / 50%);
}
.modal-backdrop {
	z-index: 1040; 
  }
  
  .modal {
	--bs-modal-border-width: 8px;
    --bs-modal-border-radius: 12px;
	z-index: 1050; 
  }
  .text-spacing {
	white-space: pre-line;
  }
  .pagination .page-item span {
	font-size: 14px;
	color: #6b6e93;
  }

.react-pdf__Page__textContent, canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
}

.css-13cymwt-control {
    border: solid 1px #dadbe1 !important;
    padding: 4px !important;
}
.view-switcher{
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	transition: box-shadow 0.2s ease-in-out;
	border-radius: 0.375rem; 
}
.view-switcher:hover {
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
}

// Calendar

.calendar-header-container {
	text-align: left;
	margin-bottom: 10px;
  }
  
  .calendar-container {
	display: grid;
	grid-template-columns: repeat(7, 1fr); 
	background-color: #e0e0e0; 
  }
  
  .calendar-col {
	// background: white;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	// border: 1px solid #f5f5f5;
  }
  
  .calendar-header {
	// color: #7b7b7b;
	font-weight: bold;
	text-align: center;
	padding: 10px;
	// border-bottom: 1px solid #f5f5f5; 

  }

  @media screen and (max-width:760px) {
	.calendar-header {
		font-size: 12px;
	  }
  }
  
  .calendar-card {
	margin: 5px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
	overflow: hidden;
  }
  
  .calendar-card .calendar-time {
	font-size: 12px;
	margin-bottom: 4px;
  }
  
  .calendar-card .calendar-details {
	font-size: 14px;
	font-weight: 600;
  }

  .appointment-type{
	font-weight: 500;
    font-size: 13px;
    color: #676767;
  }
  
  .calendar-footer {
	display: flex;
	align-items: center;
	margin-top: 40px;
	background-color: white;
	border-radius: 4px;
	padding: 2px 5px;
  }
  
  .calendar-footer .indicator {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin-right: 5px;
  }
  
  .calendar-controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	gap: 10px; 
  }
  .calendar-date-range{
	color: gray;
	font-size: 14px;
  }
  .calendar-navigation-buttons{
	display: flex;
	gap: 10px;
  }
  .calendar-controls button {
	background-color: transparent;
	border: none;
	border-radius: 4px;
	padding: 8px 16px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.2s ease;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

  }
  
  .calendar-controls button:hover {
	background-color: #5a73e6;
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-controls span {
	font-weight: bold;
	color: #333;
	font-size: 16px;
  }
  
.sort-arrow{
	font-size: 13px;
}
.asc-arrow{
	color: rgb(190, 190, 190) !important;
}
.total-container {
	margin-left: auto !important;
	text-align: right;
  }